<template>
    <v-container grid-list-xl fluid>
        <h1>Cadastro de cartão</h1>
        <v-divider class="mb-4" />
        <div v-if="$store.getters.isLogged">
            <v-card dark color="primary" v-if="cardInDatabase == false && (id.length == 14 || id.length == 8)">
                <v-card-title>
                    <v-icon large left> credit_card </v-icon>
                    <span class="text-h6 font-weight-bold">Leia com atenção:</span>
                </v-card-title>
                <v-card-text>
                    Ao clicar no botão abaixo você irá associar o cartão ao seu usuário, após associação todos os carregamentos serão associados ao seu usuário, por isso é muito
                    importante utilizar o menu "Meus cartões" para bloqueio temporário ou até mesmo apagar em caso de perda.
                </v-card-text>

                <v-divider class="my-4 info" style="opacity: 0.22" />

                <v-card-actions class="justify-center"> <v-btn color="success" :disabled="block" block @click="send"> DESBLOQUEAR </v-btn></v-card-actions>
            </v-card>

            <v-alert text color="warning" v-if="cardInDatabase">
                <h3 class="text-h5 font-weight-bold">Cartão já cadastrado ou excluído.</h3>
                <div class="mb-12">Verifique se este cartão já não se encontra na sua lista de cartões, caso contrário entre em contato clicando abaixo.</div>
                <v-btn block tile color="grey" dark :href="duplicateHref" target="_blank"> <v-icon left> fab fa-whatsapp </v-icon> Reportar Problema </v-btn>
            </v-alert>

            <v-alert text color="error" v-else-if="error">
                <h3 class="text-h5 font-weight-bold">Problema ao identificar cartão</h3>
                <div class="mb-12">Entre em contato para reportar o problema clicando abaixo.</div>
                <v-btn block tile color="grey" dark :href="errorHref" target="_blank"> <v-icon left> fab fa-whatsapp </v-icon> Reportar Problema </v-btn>
            </v-alert>
        </div>
        <div v-else>
            <v-alert text color="error" icon="app_blocking">
                <h3 class="text-h5 font-weight-bold">Funcionalidade apenas para usuários cadastrados, faça login ou cadastre-se para prosseguirmos!!!</h3>
            </v-alert>
        </div>
    </v-container>
</template>

<script>
export default {

    mounted() {
        if (this.$store.getters.isLogged) {
            if (this.$route.params.id) {
                this.id = this.$route.params.id;
                if (this.id.length != 14 && this.id.length != 8) {
                    this.error = true;
                } else {
                    this.check();
                }
            } else {
                this.error = true;
            }
        }
    },

    data() {
        return {
            id: "",
            error: false,
            cardInDatabase: false,
            block: false,
        };
    },

    computed: {
        errorHref() {
            return encodeURI(`https://wa.me/5511973778065?text=Erro ao cadastrar cartão ${this.id}`);
        },
        duplicateHref() {
            return encodeURI(`https://wa.me/5511973778065?text=Não consegui cadastrar esse cartão ${this.id}`);
        },
    },

    methods: {
        send() {
            this.block = true;
            this.$http
                .post("api/v2/mycards", { id: this.id })
                .then((result) => {
                    this.block = false;
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Enviado com sucesso.");
                    this.$router.replace({ path: "/mycards" });
                })
                .catch((error) => {
                    this.block = false;
                    this.error = true;
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar, tente novamente.");
                });
        },
        check() {
            this.$http.post("api/v2/checkcard", { id: this.id }).catch((error) => {
                if (error && error.cardInDatabase) {
                    this.cardInDatabase = true;
                }
                this.error = true;
            });
        },
    },
};
</script>
